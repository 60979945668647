import { faAngleLeft, faMagnifyingGlass, faSave, faShare, faShareAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Children, Component } from 'react';
import { Link } from 'react-router-dom';
import Tree from 'react-d3-tree';

import FamilyTree from 'react-family-tree';
import { toast } from 'react-toastify';
import { apiUrl } from '../../comps/Config';


class NovaArvore extends Component {
  constructor(props) {
    super(props);
    this.state = {
   selectedNodeId:[ null],
   falec:false,
   file1: null,
   file2: null,
   file3: null,
   selectedFiles: [],
   pessoa:[],
   data:[{name:'primeiro'}]}


  
  }

  componentDidMount(){
document.title = "Nova árvore"
  }

  handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      pessoa: {
        ...prevState.pessoa,
        [id]: value
      }
    }));
  }


  handleFileChange = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };

 CustomTreeNode({ nodeDatum }) {
    const { name, gender } = nodeDatum;
  
    return (
      <g onClick={()=>{
        console.log('teste')
      }}>
        <rect
          x={-50}
          y={-30}
          width={100}
          height={100}
          fill={gender === "female" ? "#f8b4b4" : "#b4d8f8"}
          stroke="black"
          strokeWidth={2}
        />
        <text x={0} y={0} dy={4} textAnchor="middle" fontSize="16" fill="black">
          {name}
        </text>
      </g>
    );
  }
  

  render() {
    var pessoa = this.state.pessoa;
    return (<>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
            this.props.navigateTo(-1)
        }}>
 <FontAwesomeIcon icon={faAngleLeft}/> Nova árvore</button>
        </div>
        </header>
    
        <div class="container-fluid full-height">
    <div class="row h-100">
      <div class="col-12 col-md-6 bg-primary text-white d-flex justify-content-center align-items-center">
     
     
      <div class="container mt-5">
    <div class="card">
      <div class="card-header">
        Dados
      </div>
      <div class="card-body add-usr">

      <div class="mb-3">
          
            <input placeholder='Nome:' type="text" class="form-control" id="nome"  onChange={this.handleInputChange} autocomplete="off" />
          </div>


          <div class="mb-3">
            <input placeholder='Sobrenome:' type="text" class="form-control" id="sobrenome" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="nascimento" class="form-label">Data do nascimento</label>
            <input placeholder='Digite:' type="date" class="form-control" id="nascimento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>


          <div class="mb-3">
            <input placeholder='Local do nascimento:' type="text" class="form-control" id="sobrenome" onChange={this.handleInputChange} autocomplete="off"/>
          </div>



         

         

          <div class="mb-3">
            <label for="casamento" class="form-label">Data do casamento</label>
            <input placeholder='Digite:' type="date" class="form-control" id="casamento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

     
         {/* <div class="mb-3">
          <label for="falecimento" class="form-label">Falecido(a) ? </label>
          <input type='checkbox' onChange={(e)=>{
            this.setState({falec:e.target.checked})
          }} style={{marginLeft:10}}/> */}
          
        {true && <>
          <div class="mb-3">
            <label for="falecimento" class="form-label">Data do falecimento</label>
            <input placeholder='Digite:' type="date" class="form-control" id="falecimento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="local_falecimento" class="form-label">Local de Falecimento</label>
            <input placeholder='Digite:' type="text" class="form-control" id="local_falecimento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
            <label for="falecimento-file" class="form-label">Anexos</label>
            <input type="file" multiple class="form-control" name="file3" onChange={this.handleFileChange} id="falecimento-file"/>
          </div>
        </>}
         

          <div class="mb-3">
            <label for="observacoes" class="form-label">Observações</label>
            <textarea placeholder='Digite:' type="text" class="form-control" id="observacoes" onChange={this.handleInputChange} autocomplete="off"/>
          </div>


        <div class="mb-3 d-flex justify-content-center">
            


            <button className='btn btn-primary' onClick={(event)=>{
            
            if(pessoa.nome == undefined || pessoa.nome.trim().length == 0){
              toast.error("Informe o nome.")
              return;
            }

            if(pessoa.sobrenome == undefined || pessoa.sobrenome.trim().length == 0){
              toast.error("Informe o sobrenome.")
              return;
            }
            
          

   
            


          

         

            
        

            
            
            event.target.classList.add("load")
            const formData = new FormData();

            if(this.state.selectedFiles.length){

              for (let i = 0; i < this.state.selectedFiles.length; i++) {
                formData.append('files', this.state.selectedFiles[i]);
              }
  
            }
           
            Object.keys(pessoa).forEach(key => {
              formData.append(key,pessoa[key]);
            });

            fetch(`${apiUrl}/tree/add/membro/`, {
              method: 'POST',
              body:formData,
            })
              .then(response => {
                event.target.classList.remove("load")
                if(response.status == 200){
                  response.json().then(data=>{
                this.props.navigateTo("/arvore/"+data.code)
                  })
                }
              }).catch(data=>{
                toast.error("falha ao enviar!")
              })

            }}>
<FontAwesomeIcon icon={faSave}/> Salvar
            </button>


          

        </div>



      </div>
    </div>
  </div>

   
      </div>
      <div class="col-12 col-md-6 bg-secondary text-white d-flex justify-content-center align-items-center">
     

      <Tree
        data={this.state.data}
        orientation="vertical"
        translate={{ x: 450, y: 100 }}
        pathFunc="step"
        nodeSvgShape={{
          shape: 'rect',
          shapeProps: {
            width: 120,
            height: 60,
            x: -60,
            y: -30,
            strokeWidth: 2,
            stroke: 'black',
            fill: '#f0f0f0',
          },
        }}
        styles={{
          links: {
            link: {
              stroke: '#000000',
              strokeWidth: 2,
            },
          },
        }}
        separation={{ siblings: 1, nonSiblings: 2 }}
        renderCustomNodeElement={(rd3tProps) => (
          <this.CustomTreeNode {...rd3tProps} />
        )}
      />

      </div>
    </div>
  </div>

        </>
    );
  }
}

export default NovaArvore;