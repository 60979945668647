import { faAngleLeft, faClose, faMagnifyingGlass, faShare, faShareAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    loader:true,
    busca:'',
    search:undefined
    };
  }

  componentDidMount(){
document.title = 'Home'
fetch(`${apiUrl}/results`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  }
})
  .then(response => {
    response.json().then(data=>{
      this.setState({data,loader:false})
    })
  })
  }

  render() {
    if(this.state.loader){
      return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <div className='loader'>
        <div class="spinner"></div>
        </div>
        </div>)
    }
    var {data} = this.state;
    return (<>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight">
    Home</button>
        </div>
        </header>
    
        <div class="container-fluid full-height">
    <div class="row h-100">
      <div class="col-12 col-md-6 bg-primary text-white d-flex justify-content-center align-items-center">
     
      <div class="container mt-5 mb-5">
    <div class="card">
      <div class="card-body">
    
        <div class="mb-3">
          <div class="input-group" style={{
            
          }}>
           
            <input type="text" class="form-control" id="searchInput" onChange={(e)=>{
             if(e.target.value.length == 0){
              this.setState({search:undefined})
              return;
             }
             this.setState({busca:e.target.value})
            }} placeholder="Digite para buscar..."/>
            <button class="btn-psq3">
              <FontAwesomeIcon icon={faClose}/>
            </button>
            <button class="btn btn-primary" onClick={(e)=>{
              var value = this.state.busca;
         
              if(value.trim().length == 0){
                return;
              }

              e.target.classList.add('load')

              fetch(`${apiUrl}/buscar/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  // outras headers, se necessário
                },
                body: JSON.stringify({
                value
                }), // corpo da requisição em formato JSON
              })
                .then(response => {
                 
                  e.target.classList.remove('load')  
                 

if(response.status == 200){
response.json().then(search=>{
  this.setState({search})
})
}


                })


            }}>
           <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </button>
          </div>
        </div>
        <div id="resultList">
      
      {this.state.search == undefined ? <>
        {data.length > 0 ? <>
            <div class="list-group">
              {data.map((item)=><>
                <div class="list-group-item" onClick={()=>{
                  this.props.navigateTo("/arvore/"+item.code)
                }}>  <FontAwesomeIcon color='#282828' icon={faShareAlt}/> 
                <span>{item.sobrenome}</span>
                 </div>
              </>)}
            </div>
          </> : <>
          Sem resultados
          </>}
      </> : <>
      
     {this.state.search.length > 0 ? <>
     
      <div class="list-group">
              {this.state.search.map((item)=><>
                <div class="list-group-item" onClick={()=>{
                  this.props.navigateTo("/arvore/"+item.code)
                }}>  <FontAwesomeIcon color='#282828' icon={faShareAlt}/> 
                <span>{item.sobrenome}</span>
                 </div>
              </>)}
            </div>
     
     </> : <>Não foram encontrados resultados!</>}
         
      </>}
          
          
        
        </div>
      </div>
    </div>
  </div>

   
      </div>
      <div class="col-12 col-md-6 bg-secondary text-white d-flex justify-content-center align-items-center">
     

<button className='btn btn-light' onClick={()=>{

  this.props.navigateTo('/nova-arvore')
}}>
  <FontAwesomeIcon icon={faShareAlt}/> NOVA ARVORE GENEALOGICA
</button>

      </div>
    </div>
  </div>

        </>
    );
  }
}

export default Home;