import React, { Component } from 'react';
import { apiUrl } from '../../comps/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faPen, faSave, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import doc from "../../images/doc.png"
import Tree from 'react-d3-tree';
import ex from "../../images/ex.webp"
import pd from "../../images/pdf.png"
import { toast } from 'react-toastify';
class TreePrev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{},
      loader:true,
      falec:false,
      tree:[]
    };
  }

componentDidMount(){
    document.title = "Árvore"
    fetch(`${apiUrl}/arvore/show/`+window.location.pathname.split("/")[2], {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // outras headers, se necessário
        },
      })
        .then(response => {
if(response.status == 200){
response.json().then(data=>{
 
    this.setState({
        data,
        loader:false,
        tree:data.tree
    })
})
}else{

}
        })

    
}

handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [id]: value
      }
    }));
  }


  handleFileChange = (e) => {
    const file = e.target.files[0];
    
    // Verifica se o arquivo é um PDF
    if (file && file.type !== 'application/pdf') {
      toast.warn("Apenas arquivos PDF são permitidos!")
      e.target.value = ''
      return;
    }

    // Se for um PDF válido, atualiza o estado e limpa o erro
    this.setState({
      [e.target.name]: file,
      error: '', // Limpa qualquer mensagem de erro anterior
    });
  };

CustomTreeNode({ nodeDatum }) {
    const { name, gender } = nodeDatum;
  
 return (
      <g onClick={()=>{
        console.log('teste')
      }}>
        <rect
          x={-50}
          y={-30}
          width={100}
          height={100}
          fill={gender === "female" ? "#f8b4b4" : "#b4d8f8"}
          stroke="black"
          strokeWidth={2}
        />
        <text x={0} y={0} dy={4} textAnchor="middle" fontSize="16" fill="black">
          {name}
        </text>
      </g>
    );
  }


  TreeNode = ({ node, x, y, id}) => {
  
 
    return (
      <g  >
        <circle cx={x} cy={y} r={30} fill="lightblue" strokeWidth="2" stroke={this.state.selected == id ? "blue" : "black"} />
        <text x={x} y={y-18}   transform={`rotate(180, ${x}, ${y - 10})`} textAnchor="middle" dy=".3em">
          {node.label}
        </text>
      </g>
    );
  };
  
  // Função recursiva para renderizar os nós e as linhas de ligação
 Tree = ({ nodes, parent, x, y, spacing }) => {
    const totalWidth = (nodes.length - 1) * spacing; // Calcula a largura total do grupo de filhos
    const startX = x - totalWidth / 2; // Ajusta a posição inicial para centralizar o grupo de filhos
  
    return (
      <>
        {nodes.map((node, index) => {
          const newX = startX + index * spacing; // Ajuste horizontal para espaçar os nós
          const newY = y + 100;
  
          return (
            <g key={node.id} >
              {/* Linha que liga o nó atual ao nó pai */}
              {parent && (
                <line
                  x1={x}
                  y1={y+30}
                  x2={newX}
                  y2={newY}
                  stroke="black"
                  strokeWidth="2"
                />
              )}
  
              {/* Nó da árvore */}
              <this.TreeNode node={node} id={node.id} x={newX} y={newY} />
  
              {/* Renderizar filhos do nó atual */}
              {node.children && (
                <this.Tree nodes={node.children} parent={node} x={newX} y={newY} spacing={spacing / 2} />
              )}
            </g>
          );
        })}
      </>
    );
  };




  render() {
    if(this.state.loader){
        return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
          <div className='loader'>
          <div class="spinner"></div>
          </div>
          </div>)
      }
      if(this.state.data.length ==0){
        return(<>
        <div class="p404 doc">
   <img src={doc}/>
   <p>Desculpe, Esse documento não foi encontrado.</p>
   <button  class="btn btn-primary" onClick={()=>{
    this.props.navigateTo(-1)
   }}>
     <FontAwesomeIcon icon={faArrowLeft} />
      Voltar
   </button>
</div>

        </>)
      }
      var d = this.state.data;
      return (<>
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
              this.props.navigateTo(-1)
          }}>
   <FontAwesomeIcon icon={faAngleLeft}/> Árvore</button>
          </div>
          </header>
      
          <div class="container-fluid full-height">
    <div class="row h-100">
      <div class="col-12 col-md-6 bg-primary text-white d-flex justify-content-center align-items-center">
     
     
      <div style={{
        padding:10
      }}>
        <div style={{
            display:'table',
            margin:'auto'
        }}>
<button className='btn btn-success' onClick={()=>{
    this.props.navigateTo(`/arvore/${document.location.pathname.split("/")[2]}/edit`)
}}><FontAwesomeIcon icon={faPen}/> Editar</button>

        </div>
        <div className='mt-3'>
        <button className='btn btn-light' onClick={()=>{
    this.props.navigateTo(`/nova-arvore`)
}}><FontAwesomeIcon icon={faShareAlt}/> NOVA ÁRVORE GENEALOGICA</button>
 
        </div>

        <div className='mt-2' style={{display:'flex',alignItems:'center'}}>
<span>Download</span>

<a href={`${apiUrl}/arvore/${window.location.pathname.split("/")[2]}/baixar/exel`}>
<img src={ex} style={{width:75,height:60,cursor:'pointer'}} />
</a>

<img src={pd} style={{width:40,height:40,cursor:'pointer'}} />

        </div>
    </div>

   
      </div>
      <div class="col-12 col-md-6 bg-secondary text-white d-flex justify-content-center align-items-center">
     
     
      <svg width="100%" height="500">
      <g transform="rotate(180, 500, 250)">
        <this.Tree  nodes={[
    {
        label:this.state.data.tree.primary != undefined ? this.state.data.tree.primary[0].nome + ' ' + this.state.data.tree.primary[0].sobrenome  : 'Não informado',
        id:1,
        children:[
        {label:this.state.data.tree.mae != undefined ? this.state.data.tree.mae[0].nome + ' ' + this.state.data.tree.mae[0].sobrenome  : 'Não informado'
            
            ,
              id:2,children:[{label:this.state.data.tree.mae_mae != undefined ? this.state.data.tree.mae_mae[0].nome + ' ' + this.state.data.tree.mae_mae[0].sobrenome  : 'Não informado'
                ,id:3},{label:"Avô",id:4}]},
            {label:this.state.data.tree.pai != undefined ? this.state.data.tree.pai[0].nome + ' ' + this.state.data.tree.pai[0].sobrenome  : 'Não informado',
              
              id:5,children:[{label:"Avó",id:6},{label:"Avô",id:7}]},
        ]
    },
      
  ]} parent={null} x={500} y={100} spacing={500} />
        </g>
      </svg>
     
   

      </div>
    </div>
  </div>
    
  
          </>
      );
  }
}

export default TreePrev;
