import React, { Component } from 'react';
import { apiUrl } from '../../comps/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import doc from "../../images/doc.png"
import { Confirm,StaticDialog } from 'react-st-modal';
import { toast } from 'react-toastify';
import { Children } from 'react';
import Skeleton from '../../comps/Skeleton';
class Arvore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      loader:true,
      falec:false,
      tree:[],
      f1:false,
      selected:1,
      bsc:true,
      pessoa:{},
      file1: null,
   file2: null,
   file3: null,
   cdd:true,
    };
  }






componentDidMount(){
    document.title = "Editar pessoa"
    fetch(`${apiUrl}/arvore/`+window.location.pathname.split("/")[2], {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // outras headers, se necessário
        },
      })
        .then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({
        data,
        loader:false,
        pessoa:data
       
    })
})
}else{

}
        })
}

handleInputChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      pessoa: {
        ...prevState.pessoa,
        [id]: value
      }
    }));
  }


  handleFileChange = (e) => {
    const file = e.target.files[0];
    
    // Verifica se o arquivo é um PDF
    if (file && file.type !== 'application/pdf') {
      toast.warn("Apenas arquivos PDF são permitidos!")
      e.target.value = ''
      return;
    }

    // Se for um PDF válido, atualiza o estado e limpa o erro
    this.setState({
      [e.target.name]: file,
      error: '', // Limpa qualquer mensagem de erro anterior
    });
  };


 TreeNode = ({ node, x, y, id}) => {
  
 
    return (
      <g onClick={(e)=>{
       this.setState({selected:id})
       this._buscar(id);
      }} >
        <circle cx={x} cy={y} r={30} fill="lightblue" strokeWidth="2" stroke={this.state.selected == id ? "blue" : "black"} />
        <text x={x} y={y-18}   transform={`rotate(180, ${x}, ${y - 10})`} textAnchor="middle" dy=".3em">
          {node.label}
        </text>
      </g>
    );
  };
  
  // Função recursiva para renderizar os nós e as linhas de ligação
 Tree = ({ nodes, parent, x, y, spacing }) => {
    const totalWidth = (nodes.length - 1) * spacing; // Calcula a largura total do grupo de filhos
    const startX = x - totalWidth / 2; // Ajusta a posição inicial para centralizar o grupo de filhos
  
    return (
      <>
        {nodes.map((node, index) => {
          const newX = startX + index * spacing; // Ajuste horizontal para espaçar os nós
          const newY = y + 100;
  
          return (
            <g key={node.id} >
              {/* Linha que liga o nó atual ao nó pai */}
              {parent && (
                <line
                  x1={x}
                  y1={y+30}
                  x2={newX}
                  y2={newY}
                  stroke="black"
                  strokeWidth="2"
                />
              )}
  
              {/* Nó da árvore */}
              <this.TreeNode node={node} id={node.id} x={newX} y={newY} />
  
              {/* Renderizar filhos do nó atual */}
              {node.children && (
                <this.Tree nodes={node.children} parent={node} x={newX} y={newY} spacing={spacing / 2} />
              )}
            </g>
          );
        })}
      </>
    );
  };


_buscar(q){

  this.setState({bsc:true})
   switch(Number(q)){

    case 1:
      var f = "primary";
    break;

    case 2:
      var f = "mae";
    break;

    case 3:
      var f = "mae-mae";
    break;

    case 4:
      var f = "mae-pai";
    break;

    case 5:
      var f = "pai";
    break;

    case 6:
      var f = "pai-mae";
    break;

    case 7:
      var f = "pai-pai";
    break;

    default:
      var f = "falha"
    

  }
 

  fetch(`${apiUrl}/listar/${window.location.pathname.split("/")[2]}/${q}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // outras headers, se necessário
    }
  })
    .then(response => {
      response.json().then(d=>{
        
        if(Object.keys(d).length === 0){
this.setState({cdd:false})
        }else{
          this.setState({cdd:true})
        }

        this.setState((prevState) => ({
          pessoa: {
            ...prevState.pessoa,
            'nome':d.nome || '',
            'sobrenome':d.sobrenome || '',
            'data_nac':d.data_nac || '',
            'cert_nac':d.cert_nac || '',
            'casamento':d.casamento || '',
            'cert_casa':d.cert_casa || '',
            'falec':d.falec || '',
            'local_falec':d.local_falec || '',
            'cert_falec':d.cert_falec || '',
            'obs':d.obs || '',
            tipo:f
          }
        }));




      })
    })


}


handleFileChange = (e) => {
  const file = e.target.files[0];
  
  // Verifica se o arquivo é um PDF
  if (file && file.type !== 'application/pdf') {
    toast.warn("Apenas arquivos PDF são permitidos!")
    e.target.value = ''
    return;
  }

  // Se for um PDF válido, atualiza o estado e limpa o erro
  this.setState({
    [e.target.name]: file,
    error: '', // Limpa qualquer mensagem de erro anterior
  });
};
  
  render() {
    if(this.state.loader){
        return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
          <div className='loader'>
          <div class="spinner"></div>
          </div>
          </div>)
      }
      if(this.state.data.length ==0){
        return(<>
        <div class="p404 doc">
   <img src={doc}/>
   <p>Desculpe, Esse documento não foi encontrado.</p>
   <button  class="btn btn-primary" onClick={()=>{
    this.props.navigateTo(-1)
   }}>
     <FontAwesomeIcon icon={faArrowLeft} />
      Voltar
   </button>
</div>

        </>)
      }
      var d = this.state.pessoa;
      return (<>
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
              this.props.navigateTo(-1)
          }}>
   <FontAwesomeIcon icon={faAngleLeft}/> Editar pessoa</button>
          </div>
          </header>
      
          <div class="container-fluid full-height">
    <div class="row h-100">
      <div class="col-12 col-md-6 bg-primary text-white d-flex justify-content-center align-items-center">
     
     
      <div class="container mt-5">
       
      <div class="card">
      <div class="card-header">
        Dados
      </div>
      <div class="card-body add-usr">
     
<div class="mb-3">
ID: {window.location.pathname.split("/")[2]}
</div>
      <div class="mb-3">
            <label for="nome" class="form-label">Nome</label>
            <input placeholder='Digite:' value={d.nome} type="text" class="form-control" id="nome"  onChange={this.handleInputChange} autocomplete="off" />
          </div>


          <div class="mb-3">
            <label for="sobrenome" class="form-label">Sobrenome</label>
            <input placeholder='Digite:' value={d.sobrenome} type="text" class="form-control" id="sobrenome" onChange={this.handleInputChange} autocomplete="off"/>
          </div>


<hr/>

          <div class="mt-3 mb-3">
            <label for="nascimento" class="form-label">Nascimento</label>
            <input placeholder='Digite:' value={d.data_nac} type="date" class="form-control" id="data_nac" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
          {d.cert_nac != "" ? <>
                 
          </> : <>

          <label for="nascimento-file" class="form-label">Anexar Certidão de Nascimento</label>
          <input  type="file" class="form-control"  name="file1" onChange={this.handleFileChange} id="nascimento-file"/>
         
          </>}
          
          </div>
<hr/>
          <div class="mt-1 mb-3">
            <label for="casamento" class="form-label">Casamento</label>
            <input placeholder='Digite:' value={d.casamento} type="date" class="form-control" id="casamento" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-3">
          


          {d.cert_casa != "" ? <>
               
          </> : <>
         
          <label for="nascimento-file" class="form-label">Anexar Certidão de Casamento</label>
          <input  type="file" class="form-control" name="file2" onChange={this.handleFileChange} id="nascimento-file"/>
         


          </>}
          

         
         
          </div>

          <hr/>
      
        {true && <>
          <div class="mt-1 mb-3">
            <label for="falecimento" class="form-label">Falecimento</label>
            <input placeholder='Digite:' value={d.falec} type="date" class="form-control" id="falec" onChange={this.handleInputChange} autocomplete="off"/>
          </div>


          <div class="mb-3">
            <label for="local_falecimento" class="form-label">Local de Falecimento</label>
            <input placeholder='Digite:' value={d.local_falec} type="text" class="form-control" id="local_falec" onChange={this.handleInputChange} autocomplete="off"/>
          </div>

          <div class="mb-2" >
          

          {d.cert_falec != "" ? <>
              
          </> : <>
          

          <label for="nascimento-file" class="form-label">Anexar Certidão de Falecimento</label>
          <input  type="file" class="form-control" name="file3" onChange={this.handleFileChange} id="nascimento-file"/>
         



          </>}





             
          </div>
        </>}
         

          <div class="mt-1 mb-3">
            <label for="observacoes" class="form-label">Observações</label>
            <textarea placeholder='Digite:' value={d.obs}  type="text" class="form-control" id="obs" onChange={this.handleInputChange} autocomplete="off"/>
          </div>


        <div class="mb-3 d-flex justify-content-center">
            

{this.state.cdd == false ? <>
  <button className='btn btn-primary' onClick={(event)=>{

if(d.nome == undefined || d.nome.trim().length == 0){
  toast.error("Informe o nome.")
  return;
}

if(d.sobrenome == undefined || d.sobrenome.trim().length == 0){
  toast.error("Informe o sobrenome.")
  return;
}















const formData = new FormData();

            formData.append('file1', this.state.file1);
            formData.append('file2', this.state.file2);
            formData.append('file3', this.state.file3);
            
            Object.keys(d).forEach(key => {
              formData.append(key,d[key]);
            });
            event.target.classList.add("load")
            fetch(`${apiUrl}/tree/add/new/`, {
              method: 'POST',
              body:formData,
            })
              .then(response => {
                event.target.classList.remove("load")
                if(response.status == 200){
                  event.target.classList.remove("load")
                  response.json().then(data=>{
                   this.props.navigateTo("/arvore/"+data.code)
                  })
                }
              }).catch(data=>{
                toast.error("falha ao enviar!")
              })



  }}>
<FontAwesomeIcon icon={faSave}/> Salvar
            </button>
</> : <>
<button className='btn btn-primary' onClick={()=>{

fetch(`${apiUrl}/tree/${document.location.pathname.split("/")[1]}/update/member`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // outras headers, se necessário
  },
  body: JSON.stringify(d), // corpo da requisição em formato JSON
})
  .then(response => {
   
    
   

if(response.status == 200){

}

})

}}>
<FontAwesomeIcon icon={faSave}/> Atualizar
            </button>
</>}
          


          

        </div>



      </div>
    </div>
    

  </div>

   
      </div>
      <div class="col-12 col-md-6 bg-secondary text-white d-flex justify-content-center align-items-center">
     
      <svg width="100%" height="500">
      <g transform="rotate(180, 500, 250)">
        <this.Tree  nodes={[
    {
        label:"filho",
        id:1,
        children:[
            {label:"Mãe",id:2,children:[{label:"Avó",id:3},{label:"Avô",id:4}]},
            {label:"Pai",id:5,children:[{label:"Avó",id:6},{label:"Avô",id:7}]},
        ]
    },
      
  ]} parent={null} x={500} y={100} spacing={500} />
        </g>
      </svg>
     

      </div>
    </div>
  </div>
    
  <StaticDialog
        isOpen={this.state.f1}
        title="Custom static dialog"
        onAfterClose={(result) => {
          this.setState({f1:false})
        }}
    >

      </StaticDialog>
  
          </>
      );
  }
}

export default Arvore;
